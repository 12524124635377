import * as React from "react";
import { Helmet } from "react-helmet";
import { Layout } from '../components';


// markup
const PrivacyPolicy = ({ location }) => {


	return (
		<Layout location={location}>

			<Helmet>
				<link href="/assets/css/privacy-policy--and--terms-of-service--and--conflict-resolution.css" rel="stylesheet" type="text/css" />
            </Helmet>



			<section data-name="layout" id={'privacy_policy_page'}>
				<div className={'page-content container-fluid col-10 col-xl-8'}>

					<div className={'title'}>Privacy Policy</div>
					<div className={'text-content'}>
						This version was last updated December the 14th <strong>2021</strong >.<br /><br />
						Welcome to our privacy policy.
						<br /><br /><br />
					</div>


					<div className={'subtitle'}>About us</div>
					<div className={'text-content'}>
						Thank you for visiting Ephemeral Ethernal.
						<br /><br />
						Whether you are visiting just once or you intend to come back, you must read our
						privacy policy.
						<br /><br />
						We are Ephemeral Ethernal, Lda. trading as Ephemeral Ethernal © and respecting
						your privacy and protecting your personal information is very important to us.
						<br /><br />
						This document applies to the information collected by Ephemeral Ethernal as a
						controller, including the information collected on our website or through other
						channels. If you do not agree with the data practices described below, you should
						not use this website.
						<br /><br />
						Ephemeral Ethernal is an NFT platform, including original works by a wide selection
						of contemporary artists articulating exceptionally poignant and unique creative
						voices. Emerging from the growing debate surrounding the materiality, perpetuity
						and inherent value of artworks, along with the very nature of art itself, it represents a
						provocation: plunging into the future and questioning the structures that support our
						concept and experience of modernity.
						<br /><br />
						Composed exclusively of NFT-based works, Ephemeral Ethernal fully embodies the
						spirit of this new technology, upending the rules of digital ownership and clearing the
						way towards the end of ephemeral art, evolving or even elevating it into a new plain
						of existence, akin to that of what was once understood as the ether: weightless,
						transparent, frictionless, undetectable chemically or physically, and literally
						permeating all matter and space.
						<br /><br />
						Ephemeral Ethernal, Lda. is responsible for your personal data, as laid out in this
						privacy policy. Our registered office address is Rua Vale Formoso de Cima, n. º 1
						tornejando para a rua Zófimo Pedroso, n. º 26 a 30, 1950-265 Lisboa, Portugal, and
						our Portuguese VAT number is 516670522.
						<br /><br />
						If you have any questions about this privacy policy or about our use of your personal
						information, including any request to exercise your rights, please contact us at
						info@ephemeralethernal.com.
						<br /><br /><br />
					</div>

					<div className={'subtitle'}>Your rights</div>
					<div className={'text-content'}>
						We aim to be as transparent as possible about the data we hold and process in
						order to help you exercise those rights.
						<br /><br />
						If you are an EU resident, you have the following legal rights:
						<br /><br />
						<div className={'list'}>
							<ul>
								<li>
									Access – the right to request a copy of the personal data about you that we hold.
								</li>
								<li>
									Rectification – the right to request the correction of any personal data that it is found to be inaccurate or
									out of date.
								</li>
								<li>
									Erasure – the right to request that we delete your personal data.
								</li>
								<li>
									Restriction of processing – the right to request a temporary or permanent restriction in relation to the
									processing of some or all your personal data.
								</li>
								<li>
									Objection – the right to object to the processing of personal data when it is linked to direct marketing.
								</li>
								<li>
									Complain – the right to lodge a complaint.
								</li>
								<li>
									Portability – the right to request a copy of the data subject with your personal data held by the data
									controller in order to transmit the data to another data controller.
								</li>
								<li>
									Withdrawal of consent – the right to unsubscribe our newsletter at the bottom of each email received or
									by emailing us as set out below.
								</li>
							</ul>
						</div>
					</div>

					<div className={'text-content'}>
						Please contact us at info@ephemeralethernal.com if you would like to exercise any
						of the rights set out above regarding your personal data.
						<br /><br />
						We do not usually require any fee for the exercise of your legal rights. However, we
						may charge a reasonable fee if your request is clearly unfounded, repetitive or
						excessive. Alternatively, we may refuse to comply with your request in these
						circumstances.
						<br /><br />
						We will do our best to respond to your request as soon as possible, within a
						maximum period of 30 days. If your request is particularly complex, we will notify you
						and keep you updated. Sometimes, we may need to contact you to ask for further
						information. This security measure aims to ensure that personal information is not
						disclosed to any person who has no right to access it.
						<br /><br /><br />
					</div>


					<div className={'subtitle'}>Personal Information Collected</div>
					<div className={'text-content'}>
						Personal information means any information about an individual from which that
						person can be identified. This can include information that you voluntarily provide us
						with, information about you that is available on the Internet and information that we
						collect from companies that provide services to us. We will try to only collect the data
						that is necessary to carry out our business and provide you with personalised
						content.
						<br /><br />
						When you register on our website, contact us, subscribe to our newsletter, buy a
						piece from our website, or simply interact with us, we will collect information from
						and about you, such as your name, contact details, e-mail, phone number, address,
						country, VAT and ID information, age, gender and personal interests. This
						information is obtained with your consent, seeing as it comes directly from you.
						<br /><br />
						When you visit our website, information about your operation system and browser,
						including its preferences and settings, may be collected by our IT systems and the
						applications that we use.
						<br /><br />
						Your personal information is collected and processed by our IT systems, in
						compliance with our obligations under the GDPR (General Data Protection
						Regulation). We use Google Analytics, a web analytics tool provided by Google, for
						data analysis purposes; Shopify, an e-commerce platform provided by Shopify Inc.,
						for the billing and the management of our online store; Odoo, a software provided by
						Odoo SA, for invoicing purposes; Putler by Leap Ventures, Inc., for customer
						relationship management purposes; Artsy and Arternal as our CRM tools and
						OpenSea.io, by Ozone Networks Inc.
						<br /><br />
						We may need the services of specialised IT companies in order to improve our
						website, including features related to the collection and processing of personal data.
						In such a case, the IT company shall access the personal data collected and
						processed by our IT systems. We will endeavour to make sure that all companies
						that provide IT services for us adopt the most adequate privacy practices, in line with
						our own privacy policy.
						<br /><br /><br />
					</div>

					<div className={'subtitle'}>Cookie Policy</div>
					<div className={'text-content'}>
						We may also collect cookies when you visit our site and use its features.
						<br /><br />
						A cookie is a small file that is sent to your browser and stored on your computer or
						device when you visit certain websites. When you visit our website, we may collect
						information from you automatically through cookies or similar technology.
						<br /><br />
						Our website uses cookies to remember you and distinguish you from other users in
						order to offer increased functionality. This allows us to improve our website and to
						provide you with a better online experience.
						<br /><br />
						We use the following types of cookies:
						<br /><br />
						Strictly necessary cookies – These are required for the operation of our website.
						<br /><br />
						Performance cookies – These cookies are necessary to allow you to browse our
						website, use its features and access private areas.
						<br /><br />
						Functionality cookies – These allow us to remember you when you return to our
						website and enable us to personalize our content for you.
						<br /><br />
						Advertising cookies.
						<br /><br />
						When you place an order on our website, we may collect information about it.
						Through cookies, we collect information about how you access our website and
						services and about your activity on our website.
						<br /><br />
						Please be aware that third parties may also use cookies on our website. Shopify,
						Google Analytics, Google Maps, Youtube, Facebook, Instagram, Twitter, Discord,
						Telegram and OpenSea, may use cookies to collect information about how visitors
						use our website and to track visitors. We recommend that you to be informed about
						their policies. We cannot access the cookies of third parties.
						<br /><br />
						When you are linked from and to the websites of our third parties, you must consult
						those website's cookie policies and practices as our cookie policy does not apply.
						<br /><br />
						Cookies are essential for the functioning of the Internet, featuring several benefits to
						companies that provide services online. Please note that cookies will not affect the
						proper functioning of your computer or device.
						<br /><br />
						If you have questions or comments about this cookie policy, please let us know by
						contacting info@ephemeralethernal.com.
						<br /><br /><br />
					</div>

					<div className={'subtitle'}>Information Processing</div>
					<div className={'text-content'}>
						We need to collect and use information about you, so that you can use our website,
						shop online and learn about our offers and news. Some details about you are
						necessary in order to prevent and detect fraud.
						<br /><br />
						We may use information about you that is shared to us by other applications or
						websites that provide their services through our website, including Metamask, a
						payment service developed by ConsenSys Software Inc., other payment providers
						(TBC), Google Analytics, Youtube, a video hosting service offered by Google,
						Instagram and Facebook, two products offered by Meta Inc., Twitter, a product
						offered by Twitter Inc., Discord, a service offered by Discord Inc., Arternal, a platform
						specialised in art galleries, and Vimeo, a video platform operated by IAC.
						Applications or websites that provide services through our website may have
						different rules regarding the processing of your personal information.
						<br /><br />
						We will also use your personal data when we need to comply with a legal or judicial
						obligation.
						<br /><br />
						Sometimes we may need to use your personal information in order to carry out tasks
						related to our business activities, including fraud prevention and website security
						maintenance. In such cases, our treatment is justified on grounds of legitimate
						interest.
						<br /><br />
						If whilst collecting personal information about you, we detect illegal activity or
						suspected illegal activity, we can use that data to take all the necessary measures
						permitted or required by laws in force in Portugal and in the EU concerning money
						laundering prevention and detection.
						<br /><br />
						The processing of personal data about you is governed and in accordance with the
						Portuguese and the EU legislation, in particular with the General Data Protection
						Regulation of 27 April 2016 (GDPR).
						<br /><br /><br />
					</div>

					<div className={'subtitle'}>Storing Information</div>
					<div className={'text-content'}>
						We retain the information you provide us for no longer than strictly necessary to
						provide you with our services and for legitimate business purposes. If you have
						registered on our website, we will keep some of your personal information, e.g., your
						details and personal interests, as long as you are a user, and if you have questions
						or claims regarding our services, we will keep it for such period.
						<br /><br />
						We put our efforts towards developing our website and making it secure for our
						visitors and users. We store information about you in applications or websites that
						we use as they are necessary for performing and improving our services.
						<br /><br />
						At your request, we will delete your personal data, unless we are legally allowed or
						required to keep certain personal data.
						<br /><br /><br />
					</div>

					<div className={'subtitle'}>Information Sharing</div>
					<div className={'text-content'}>
						Some of your personal information may be shared with the following companies:
						<br /><br />
						Silhuetas Difusas – Unipessoal, Lda., with registered office address at Avenida 25
						de Abril, 88, Pinhal de Frades, 2840-286 Seixal, Portugal and VAT number
						508734762.
						<br /><br />
						Harmonia Tropical, Lda., with registered office address at Parque Industrial do
						Barreiro, Rua 24, Armázem 5-7-9, 2830-571 Barreiro, Portugal and VAT number
						513622829.
						<br /><br />
						Iminente – Produções, Música e Arte, Lda., with registered office address at Rua do
						Comércio, 17, LJ, 2840-327 Pinhal de Frades and VAT number 515310930; and
						<br /><br />
						Geração Inadiável Produções Artísticas, Lda., with registered office address at
						Avenida 25 de Abril, 88, Pinhal de Frades2840-286 Seixal, Portugal and VAT
						number 514025921.
						<br /><br />
						Underdogs – Comércio e Produção de Artigos Decorativos, Lda, with registered
						office address at Rua Ilídio Gomes de Carvalho, nº 1, R/C Dto, 2840-268
						Seixal, Portugal, and VAT number 510985718.
						<br /><br />
						All abovementioned companies perform work for Ephemeral Ethernal and are
						obliged to protecting the privacy of personal information in line with this privacy
						policy. In these situations, we may only share your contact details, such as your
						email, for the purposes of offering products or services.
						<br /><br />
						Sometimes, we may establish collaborations with companies or organisations. Within
						the scope of these collaborations, if you interact with us or participate in the projects
						we launch, we may share strictly necessary information about you that we have
						collected with the companies or organisations that collaborate with us.
						<br /><br />
						We may also share information about you with third party applications and websites.
						We use OpenSea for billing and payment activities, and we may share your personal
						data when you place an order and conclude a payment on our website and when we
						refund you in case of return or cancellation of an order.
						<br /><br /><br />
					</div>

					<div className={'subtitle'}>Third Part Websites</div>
					<div className={'text-content'}>
						Our website may contain links to and from the websites of our artists, projects,
						collaborations, partners and sponsors. This privacy policy does not apply to those
						websites and we are not responsible or liable for the privacy practices of those
						websites.
						<br /><br /><br />
					</div>

					<div className={'subtitle'}>Changes to this Privacy Policy</div>
					<div className={'text-content'}>
						We may introduce changes to this privacy policy from time to time. Any changes we
						may make in the future will be posted on this page. We recommend that you check
						back regularly in order to get updates or see changes regarding this privacy policy.
						<br /><br />
						For those registered on our website or our newsletter, you will receive an email
						updating you about further changes, if significant to the use of the website.
						<br /><br /><br />
					</div>

					<div className={'subtitle'}>Contact Us</div>
					<div className={'text-content'}>
						If you need more information about how we process personal data or have
						questions, comments and requests regarding this privacy policy, please contact us at
						info@ephemeralethernal.com.
						<br /><br />
						If you wish to exercise any of your rights, you can let us know at
						info@ephemeralethernal.com
						<br /><br />
						If you are concerned that we may have breached your privacy and wish to make a
						complaint, you should address it to info@ephemeralethernal.com We will get back to
						you within a reasonable time up to 30 days.
						<br /><br /><br />

						All content 2021 © by Ephemeral Ethernal, Lda. trading as Ephemeral Ethernal ©.
						<br /><br />
						All rights reserved.
					</div>

				</div>

			</section >
		</Layout >
	)
}

export default PrivacyPolicy
